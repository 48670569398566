import PropTypes from 'prop-types';
import { useRef, useState, useEffect } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { formatDistanceToNow } from 'date-fns';
import { Icon } from '@iconify/react';
import bellFill from '@iconify/icons-eva/bell-fill';
import clockFill from '@iconify/icons-eva/clock-fill';
import doneAllFill from '@iconify/icons-eva/done-all-fill';
// material
import { alpha } from '@material-ui/core/styles';
import {
  Box,
  List,
  Badge,
  Button,
  Avatar,
  Tooltip,
  Divider,
  Typography,
  ListItemText,
  ListSubheader,
  ListItemAvatar,
  ListItemButton
} from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import { PATH_DASHBOARD } from '../../routes/paths';
// components
import Scrollbar from '../../components/Scrollbar';
import MenuPopover from '../../components/MenuPopover';
import { MIconButton } from '../../components/@material-extend';
import {
  getMyNotificationsRequest,
  bulkReadNotificationRequest,
  readNotificationRequest
} from '../../features/notificationSlice';
// ----------------------------------------------------------------------

const allPaths = [
  { group: 'dashboard', path: PATH_DASHBOARD.general.pageOne },
  { group: 'invoices', path: PATH_DASHBOARD.blogs.all },
  { group: 'clients', path: PATH_DASHBOARD.clients.all },
  { group: 'products', path: PATH_DASHBOARD.products.all },
  { group: 'leads', path: PATH_DASHBOARD.leads.all }
];
// ----------------------------------------------------------------------

NotificationItem.propTypes = {
  notification: PropTypes.object.isRequired,
  readFunction: PropTypes.func
};

function NotificationItem({ notification, readFunction }) {
  return (
    <ListItemButton
      onClick={() => readFunction(notification._id, notification.read, notification.group)}
      disableGutters
      sx={{
        py: 1.5,
        px: 2.5,
        mt: '1px',
        ...(notification.read === false && {
          bgcolor: 'action.selected'
        })
      }}
    >
      <ListItemAvatar>
        <Avatar sx={{ bgcolor: 'background.neutral' }}>
          <img
            alt={`svg for ${notification.group}`}
            src={`/static/icons/notificationIcons/${notification.group}.svg`}
          />
        </Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={<Typography variant="subtitle2">{notification.content}</Typography>}
        secondary={
          <Typography
            variant="caption"
            sx={{
              mt: 0.5,
              display: 'flex',
              alignItems: 'center',
              color: 'text.disabled'
            }}
          >
            <Box component={Icon} icon={clockFill} sx={{ mr: 0.5, width: 16, height: 16 }} />
            {formatDistanceToNow(new Date(notification.createdAt))}
          </Typography>
        }
      />
    </ListItemButton>
  );
}

export default function NotificationsPopover() {
  const anchorRef = useRef(null);
  const [refetch, setRefetch] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const myNotifications = useSelector((state) => state.notifications.myNotifications);
  const user = useSelector((state) => state.user.userDetails);
  const totalUnRead =
    myNotifications && myNotifications.length > 0 && myNotifications.filter((item) => item.read === false);
  const readNotifications =
    myNotifications && myNotifications.length > 0 && myNotifications.filter((item) => item.read === true);

  const navigator = (group) => {
    const found = allPaths.find((name) => name.group === group);
    if (found) {
      return found.path;
    }
    return '';
  };
  const readFunction = (id, status, group) => {
    setRefetch(!refetch);
    const navigationPath = navigator(group);
    if (status) {
      if (navigationPath) {
        navigate(navigationPath);
      } else {
        navigate(PATH_DASHBOARD.general.pageOne);
      }
    }
    if (!status) {
      dispatch(readNotificationRequest(id));
      if (navigationPath) {
        navigate(navigationPath);
      } else {
        navigate(PATH_DASHBOARD.general.pageOne);
      }
    }
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleMarkAllAsRead = () => {
    dispatch(bulkReadNotificationRequest());
    setRefetch(!refetch);
    setOpen(false);
  };

  useEffect(() => {
    if (user?._id) {
      dispatch(getMyNotificationsRequest());
    }
  }, [dispatch, user._id, refetch]);

  return (
    <>
      <MIconButton
        ref={anchorRef}
        size="large"
        color={open ? 'primary' : 'default'}
        onClick={handleOpen}
        sx={{
          ...(open && {
            bgcolor: (theme) => alpha(theme.palette.primary.main, theme.palette.action.focusOpacity)
          })
        }}
      >
        <Badge badgeContent={totalUnRead ? totalUnRead.length : 0} color="error">
          <Icon icon={bellFill} width={20} height={20} />
        </Badge>
      </MIconButton>

      <MenuPopover open={open} onClose={handleClose} anchorEl={anchorRef.current} sx={{ width: 360 }}>
        <Box sx={{ display: 'flex', alignItems: 'center', py: 2, px: 2.5 }}>
          <Box sx={{ flexGrow: 1 }}>
            <Typography variant="subtitle1">Notifications</Typography>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              You have {totalUnRead?.length} unread messages
            </Typography>
          </Box>

          {totalUnRead?.length > 0 && (
            <Tooltip title=" Mark all as read">
              <MIconButton color="primary" onClick={handleMarkAllAsRead}>
                <Icon icon={doneAllFill} width={20} height={20} />
              </MIconButton>
            </Tooltip>
          )}
        </Box>

        <Divider />

        <Scrollbar sx={{ height: { xs: 340, sm: 200, md: 300 } }}>
          <List
            disablePadding
            subheader={
              <ListSubheader disableSticky sx={{ py: 1, px: 2.5, typography: 'overline' }}>
                New
              </ListSubheader>
            }
          >
            {totalUnRead &&
              totalUnRead.length > 0 &&
              totalUnRead.map((notification) => (
                <NotificationItem key={notification._id} readFunction={readFunction} notification={notification} />
              ))}
          </List>

          <List
            disablePadding
            subheader={
              <ListSubheader disableSticky sx={{ py: 1, px: 2.5, typography: 'overline' }}>
                Read Notifications
              </ListSubheader>
            }
          >
            {readNotifications &&
              readNotifications.length > 0 &&
              readNotifications.map((notification) => (
                <NotificationItem key={notification._id} readFunction={readFunction} notification={notification} />
              ))}
          </List>
        </Scrollbar>

        <Divider />

        <Box sx={{ p: 1 }}>
          <Button fullWidth disableRipple component={RouterLink} to="#">
            View All
          </Button>
        </Box>
      </MenuPopover>
    </>
  );
}
