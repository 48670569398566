import React, { useEffect } from 'react';
import decode from 'jwt-decode';
import { PropTypes } from 'prop-types';
import { Navigate, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { setAuthorizationToken } from '../utils/setAuthorizationToken';
import { setIsAuthenticated } from '../features/appSlice';
import { getUserRequest, refreshTokenRequest } from '../features/userSlice';

const PrivateRoute = ({ children }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isAuthenticated = useSelector((state) => state.app.isAuthenticated);

  const token = localStorage.getItem('drToken');
  const refToken = localStorage.getItem('drRefToken');

  const refreshToken = async () => {
    if (!refToken) {
      localStorage.removeItem('drToken');
      dispatch(setIsAuthenticated(false)); // Dispatch action to set isAuthenticated to false
      return;
    }

    try {
      dispatch(refreshTokenRequest({ refToken: JSON.parse(refToken) }));
    } catch (error) {
      localStorage.removeItem('drRefToken');
      localStorage.removeItem('drToken');
      dispatch(setIsAuthenticated(false)); // Dispatch action to set isAuthenticated to false
      setAuthorizationToken(false);
    }
  };

  useEffect(() => {
    const refresh = async () => {
      if (!token) {
        setAuthorizationToken(false);
        dispatch(setIsAuthenticated(false)); // Dispatch action to set isAuthenticated to false
        navigate('/');
        return;
      }

      if (token) {
        const { exp } = decode(token);
        if (exp > Date.now() / 1000) {
          dispatch(setIsAuthenticated(true)); // Dispatch action to set isAuthenticated to true
          setAuthorizationToken(token);
          dispatch(getUserRequest());
        } else {
          await refreshToken();
        }
      }
    };

    refresh();
    // eslint-disable-next-line
  }, [token, dispatch, navigate]);

  return isAuthenticated ? children : <Navigate to="/" replace />;
};

PrivateRoute.propTypes = {
  children: PropTypes.node.isRequired
};

export default PrivateRoute;
