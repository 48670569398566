import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { clientAPI } from '../api/clientAPI';
import { setMessage } from './appSlice';

// async thunk request to Register new Client
export const addClientRequest = // eslint-disable-next-line
  createAsyncThunk('clients/addClientRequest', async (dataToSend, { dispatch, rejectWithValue }) => {
    try {
      const reg = await clientAPI.add(dataToSend);
      dispatch(setMessage(reg.message));
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error?.response?.data?.message);
      }
    }
  });

// async thunk request to get All clients
export const getAllClientsRequest = createAsyncThunk(
  'clients/getAllClientsRequest',
  // eslint-disable-next-line
  async (dt, { dispatch, rejectWithValue }) => {
    try {
      const data = await clientAPI.all();
      dispatch(setAllClients(data));
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error?.response?.data?.message);
      }
    }
  }
);

// async thunk request to get Single request
export const getSingleClientRequest = createAsyncThunk(
  'clients/getSingleClientRequest',
  // eslint-disable-next-line
  async (dt, { dispatch, rejectWithValue }) => {
    try {
      const data = await clientAPI.single(dt);
      dispatch(setSingleClient(data));
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error?.response?.data?.message);
      }
    }
  }
);

// async thunk request to updateExistingClientRequest
export const updateExistingClientRequest = createAsyncThunk(
  'clients/updateExistingClientRequest',
  // eslint-disable-next-line
  async (dt, { dispatch, rejectWithValue }) => {
    try {
      const data = await clientAPI.update(dt);
      dispatch(setMessage(data.message));
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error?.response?.data?.message);
      }
    }
  }
);

// async thunk request to deleteClientRequest
export const deleteClientRequest = createAsyncThunk(
  'clients/deleteClientRequest',
  // eslint-disable-next-line
  async (dt, { dispatch, rejectWithValue }) => {
    try {
      const data = await clientAPI.delete(dt);
      dispatch(setMessage(data.message));
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error?.response?.data?.message);
      }
    }
  }
);

const initialState = {
  allClients: [],
  singleClient: {},
  errorInClient: ''
};

export const clientSlice = createSlice({
  name: 'clients',
  initialState,
  reducers: {
    setAllClients: (state, action) => {
      state.allClients = action.payload;
    },
    setSingleClient: (state, action) => {
      state.singleClient = action.payload;
    },
    clearHasError: (state) => {
      state.errorInClient = '';
    }
  },
  extraReducers: {
    [addClientRequest.rejected]: (state, action) => {
      state.errorInClient = action.payload;
    },
    [getAllClientsRequest.rejected]: (state, action) => {
      state.errorInClient = action.payload;
    },
    [getSingleClientRequest.rejected]: (state, action) => {
      state.errorInClient = action.payload;
    },
    [updateExistingClientRequest.rejected]: (state, action) => {
      state.errorInClient = action.payload;
    },
    [deleteClientRequest.rejected]: (state, action) => {
      state.errorInClient = action.payload;
    }
  }
});
export const { setAllClients, setSingleClient, clearHasError } = clientSlice.actions;

export default clientSlice.reducer;
