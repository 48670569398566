// routes
import { PATH_DASHBOARD } from '../../routes/paths';
// components
import SvgIconStyle from '../../components/SvgIconStyle';

// ----------------------------------------------------------------------

const getIcon = (name) => (
  <SvgIconStyle src={`/static/icons/navbar/${name}.svg`} sx={{ width: '100%', height: '100%' }} />
);

const ICONS = {
  user: getIcon('ic_user'),
  ecommerce: getIcon('ic_ecommerce'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard'),
  banking: getIcon('ic_banking')
};

const sidebarConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: 'general',
    items: [{ title: 'Dashboard', path: PATH_DASHBOARD.general.pageOne, icon: ICONS.dashboard }]
  },
  {
    subheader: 'Orders',
    items: [{ title: 'Manage Orders', path: PATH_DASHBOARD.orders.all, icon: ICONS.user }]
  },

  // MANAGEMENT
  // ----------------------------------------------------------------------
  // commented to hide user menu in sidebar
  {
    subheader: 'Products',
    items: [
      {
        title: 'Products',
        path: PATH_DASHBOARD.products.root,
        icon: ICONS.analytics,
        children: [
          { title: 'All Products', path: PATH_DASHBOARD.products.all },
          { title: 'Add New Product', path: PATH_DASHBOARD.products.add },
          { title: 'Upload XL', path: PATH_DASHBOARD.products.upload }
        ]
      }
    ]
  },
  {
    subheader: 'Product Category',
    items: [{ title: 'Manage Product Category', path: PATH_DASHBOARD.productCategory.all, icon: ICONS.user }]
  },
  {
    subheader: 'Product Sub Category',
    items: [{ title: 'Product Sub Category', path: PATH_DASHBOARD.productSubCategory.all, icon: ICONS.user }]
  },
  {
    subheader: 'Business Leads',
    items: [{ title: 'Manage Leads', path: PATH_DASHBOARD.leads.all, icon: ICONS.user }]
  },
  {
    subheader: 'invoices',
    items: [
      {
        title: 'Invoices',
        path: PATH_DASHBOARD.invoices.root,
        icon: ICONS.analytics,
        children: [
          { title: 'All Invoices', path: PATH_DASHBOARD.invoices.all },
          { title: 'Add New Invoice', path: PATH_DASHBOARD.invoices.add }
        ]
      }
    ]
  },
  {
    subheader: 'clients',
    items: [
      {
        title: 'Clients',
        path: PATH_DASHBOARD.clients.root,
        icon: ICONS.analytics,
        children: [
          { title: 'All Clients', path: PATH_DASHBOARD.clients.all },
          { title: 'Add New Client', path: PATH_DASHBOARD.clients.add }
        ]
      }
    ]
  },
  {
    subheader: 'articles',
    items: [
      {
        title: 'Blogs',
        path: PATH_DASHBOARD.blogs.root,
        icon: ICONS.analytics,
        children: [
          { title: 'All Blogs', path: PATH_DASHBOARD.blogs.all },
          { title: 'Add New Blog', path: PATH_DASHBOARD.blogs.add }
        ]
      }
    ]
  },
  {
    subheader: ' article Tags',
    items: [{ title: 'Manage Tags', path: PATH_DASHBOARD.tags.all, icon: ICONS.banking }]
  },
  {
    subheader: 'article Category',
    items: [{ title: 'Manage Category', path: PATH_DASHBOARD.category.all, icon: ICONS.user }]
  },
  {
    subheader: 'FAQs',
    items: [{ title: 'Frequently Asked Qs', path: PATH_DASHBOARD.faqs.all, icon: ICONS.banking }]
  },
  {
    subheader: 'Business Configuration',
    items: [{ title: 'Manage Configuration', path: PATH_DASHBOARD.config.all, icon: ICONS.banking }]
  }
];

export default sidebarConfig;
