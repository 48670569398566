import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { tagAPI } from '../api/tagAPI';
import { appLoading, setMessage } from './appSlice';

// async thunk request to Register New Membership package
export const addNewTagRequest = // eslint-disable-next-line
  createAsyncThunk('tags/addNewTagRequest', async (dataToSend, { dispatch, rejectWithValue }) => {
    try {
      dispatch(appLoading(true));
      const reg = await tagAPI.add(dataToSend);
      dispatch(setMessage(reg.message));
      dispatch(appLoading(false));
    } catch (error) {
      if (error.response) {
        dispatch(appLoading(false));
        return rejectWithValue(error?.response?.data?.message);
      }
    }
  });

// async thunk request to get All membershipPackages
export const getAllTagsRequest = createAsyncThunk(
  'tags/getAllTagsRequest',
  // eslint-disable-next-line
  async (dt, { dispatch, rejectWithValue }) => {
    try {
      dispatch(appLoading(true));
      const data = await tagAPI.all();
      dispatch(setAllTags(data));
      dispatch(appLoading(false));
    } catch (error) {
      if (error.response) {
        dispatch(appLoading(false));
        return rejectWithValue(error?.response?.data?.message);
      }
    }
  }
);

// async thunk request to get Single membership Package
export const getSingleTagRequest = createAsyncThunk(
  'tags/getSingleTagRequest',
  // eslint-disable-next-line
  async (dt, { dispatch, rejectWithValue }) => {
    try {
      dispatch(appLoading(true));
      const data = await tagAPI.single(dt);
      dispatch(setSingleTag(data));
      dispatch(appLoading(false));
    } catch (error) {
      if (error.response) {
        dispatch(appLoading(false));
        return rejectWithValue(error?.response?.data?.message);
      }
    }
  }
);

// async thunk request to updateTagRequest
export const updateTagRequest = createAsyncThunk(
  'tags/updateTagRequest',
  // eslint-disable-next-line
  async (dt, { dispatch, rejectWithValue }) => {
    try {
      dispatch(appLoading(true));
      const data = await tagAPI.update(dt);
      dispatch(setMessage(data.message));
      dispatch(appLoading(false));
    } catch (error) {
      if (error.response) {
        dispatch(appLoading(false));
        return rejectWithValue(error?.response?.data?.message);
      }
    }
  }
);

// async thunk request to deleteTagRequest
export const deleteTagRequest = createAsyncThunk(
  'tags/deleteTagRequest',
  // eslint-disable-next-line
  async (dt, { dispatch, rejectWithValue }) => {
    try {
      dispatch(appLoading(true));
      const data = await tagAPI.delete(dt);
      dispatch(setMessage(data.message));
      dispatch(appLoading(false));
    } catch (error) {
      if (error.response) {
        dispatch(appLoading(false));
        return rejectWithValue(error?.response?.data?.message);
      }
    }
  }
);

const initialState = {
  allTags: [],
  singleTag: {},
  errorInTag: ''
};

export const tagSlice = createSlice({
  name: 'tags',
  initialState,
  reducers: {
    setAllTags: (state, action) => {
      state.allTags = action.payload;
    },
    setSingleTag: (state, action) => {
      state.singleTag = action.payload;
    },

    clearHasError: (state) => {
      state.errorInTag = '';
    }
  },
  extraReducers: {
    [addNewTagRequest.rejected]: (state, action) => {
      state.errorInTag = action.payload;
    },
    [getAllTagsRequest.rejected]: (state, action) => {
      state.errorInTag = action.payload;
    },
    [getSingleTagRequest.rejected]: (state, action) => {
      state.errorInTag = action.payload;
    },
    [updateTagRequest.rejected]: (state, action) => {
      state.errorInTag = action.payload;
    },
    [deleteTagRequest.rejected]: (state, action) => {
      state.errorInTag = action.payload;
    }
  }
});
export const { setAllTags, setSingleTag, clearHasError } = tagSlice.actions;

export default tagSlice.reducer;
