import axios from 'axios';

const baseURL = `${process.env.REACT_APP_API_URL}`;

export const notificationAPI = {
  myNotifications: async () => axios.get(`${baseURL}/my-notifications`).then((res) => res.data),
  readAllNotifications: async () => axios.get(`${baseURL}/bulk-read-notifications`).then((res) => res.data),
  readNotification: async (idToSearch) =>
    axios.get(`${baseURL}/read-notification/${idToSearch}`).then((res) => res.data)
};
