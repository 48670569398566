import PropTypes from 'prop-types';
// material
// import { useTheme } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';

// ----------------------------------------------------------------------

Logo.propTypes = {
  sx: PropTypes.object
};

export default function Logo({ sx }) {
  // const theme = useTheme();
  // const PRIMARY_LIGHT = theme.palette.primary.light;
  // const PRIMARY_MAIN = theme.palette.primary.main;
  // const PRIMARY_DARK = theme.palette.primary.dark;

  return (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg width="100%" height="100%" viewBox="0 0 61 42" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_3_53)">
          <g filter="url(#filter0_b_3_53)">
            <path
              d="M25 25.4167C25 21.8728 27.8728 19 31.4167 19H32.1479C35.6917 19 38.5645 21.8728 38.5645 25.4167V25.4167C38.5645 28.9605 35.6917 31.8333 32.1479 31.8333H31.4167C27.8728 31.8333 25 28.9605 25 25.4167V25.4167Z"
              fill="#FFCD1A"
            />
            <path
              d="M31.4167 19.5H32.1479C35.4156 19.5 38.0645 22.149 38.0645 25.4167C38.0645 28.6844 35.4155 31.3333 32.1479 31.3333H31.4167C28.149 31.3333 25.5 28.6844 25.5 25.4167C25.5 22.149 28.149 19.5 31.4167 19.5Z"
              stroke="#FFCD1A"
            />
          </g>
          <path
            d="M22.693 9L30.385 21.5L22.693 34L15 21.5L22.693 9Z"
            stroke="#38BDF8"
            strokeWidth="3"
            strokeLinejoin="round"
          />
          <path
            d="M30.385 21.5L22.693 9H37.837L45.77 21.5M30.385 21.5H45.77L37.837 34H22.693L30.385 21.5Z"
            stroke="#38BDF8"
            strokeWidth="3"
            strokeLinejoin="round"
          />
        </g>
        <defs>
          <filter
            id="filter0_b_3_53"
            x="21"
            y="15"
            width="21.5645"
            height="20.8333"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feGaussianBlur in="BackgroundImageFix" stdDeviation="2" />
            <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_3_53" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_3_53" result="shape" />
          </filter>
          <clipPath id="clip0_3_53">
            <rect width="61" height="42" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </Box>
  );
}
