import axios from 'axios';

const baseURL = `${process.env.REACT_APP_API_URL}`;

export const blogAPI = {
  addBlog: async (info) => axios.post(`${baseURL}/articles`, info).then((res) => res.data),
  sendURLS: async (urls) => axios.post(`${baseURL}/url-list`, urls).then((res) => res.data),
  allBlogs: async () => axios.get(`${baseURL}/articles`).then((res) => res.data),
  singleBlog: async (idToSearch) => axios.get(`${baseURL}/articles/${idToSearch}`).then((res) => res.data),
  updateBlog: async (idToSearch) => {
    const { id, ...details } = idToSearch;
    return axios.put(`${baseURL}/articles/${id}`, details).then((res) => res.data);
  },
  deleteBlog: async (idToSearch) => axios.delete(`${baseURL}/articles/${idToSearch}`).then((res) => res.data)
};
