import axios from 'axios';

const baseURL = `${process.env.REACT_APP_BASE_URL}`;

export const userAPI = {
  loginRequest: async (loginData) => axios.post(`${baseURL}/login`, loginData).then((res) => res.data),
  getCpUser: async () => axios.get(`${process.env.REACT_APP_BASE_URL}/getuser`).then((res) => res.data),
  logoutCall: async () => axios.get(`${baseURL}/logout`).then((res) => res.data),
  refreshToken: async (token) => axios.post(`${baseURL}/refresh-token`, token).then((res) => res.data),
  getConfig: async () => axios.get(`${baseURL}/get-business-config`).then((res) => res.data),
  updateConfig: async (idToSearch) => {
    const { id, ...details } = idToSearch;
    return axios.put(`${baseURL}/update-business-config/${id}`, details).then((res) => res.data);
  },
  changePassword: async () => axios.get(`${baseURL}/change-password`).then((res) => res.data)
};
