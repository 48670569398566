import axios from 'axios';

const baseURL = `${process.env.REACT_APP_API_URL}`;

export const productAPI = {
  add: async (info) => axios.post(`${baseURL}/add-new-product`, info).then((res) => res.data),
  all: async () => axios.get(`${baseURL}/products`).then((res) => res.data),
  market: async () => axios.get(`${baseURL}/get-all-market-products`).then((res) => res.data),
  single: async (idToSearch) => axios.get(`${baseURL}/single-product/${idToSearch}`).then((res) => res.data),
  update: async (idToSearch) => {
    const { id, ...details } = idToSearch;
    return axios.put(`${baseURL}/single-product/${id}`, details).then((res) => res.data);
  },
  delete: async (idToSearch) => axios.delete(`${baseURL}/single-product/${idToSearch}`).then((res) => res.data)
};
