import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { invoiceAPI } from '../api/invoiceAPI';
import { setMessage } from './appSlice';

// async thunk request to Register new Client
export const addInvoiceRequest = // eslint-disable-next-line
  createAsyncThunk('invoices/addInvoiceRequest', async (dataToSend, { dispatch, rejectWithValue }) => {
    try {
      const reg = await invoiceAPI.add(dataToSend);
      dispatch(setMessage(reg.message));
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error?.response?.data?.message);
      }
    }
  });

// async thunk request to get All clients
export const getAllInvoicesRequest = createAsyncThunk(
  'invoices/getAllInvoicesRequest',
  // eslint-disable-next-line
  async (dt, { dispatch, rejectWithValue }) => {
    try {
      const data = await invoiceAPI.all();
      dispatch(setAllInvoices(data));
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error?.response?.data?.message);
      }
    }
  }
);

// async thunk request to get Single request
export const getSingleInvoiceRequest = createAsyncThunk(
  'invoices/getSingleInvoiceRequest',
  // eslint-disable-next-line
  async (dt, { dispatch, rejectWithValue }) => {
    try {
      const data = await invoiceAPI.single(dt);
      dispatch(setSingleInvoice(data));
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error?.response?.data?.message);
      }
    }
  }
);

// async thunk request to updateInvoiceRequest
export const updateInvoiceRequest = createAsyncThunk(
  'invoices/updateInvoiceRequest',
  // eslint-disable-next-line
  async (dt, { dispatch, rejectWithValue }) => {
    try {
      const data = await invoiceAPI.update(dt);
      dispatch(setMessage(data.message));
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error?.response?.data?.message);
      }
    }
  }
);

// async thunk request to deleteInvoiceRequest
export const deleteInvoiceRequest = createAsyncThunk(
  'invoices/deleteInvoiceRequest',
  // eslint-disable-next-line
  async (dt, { dispatch, rejectWithValue }) => {
    try {
      const data = await invoiceAPI.delete(dt);
      dispatch(setMessage(data.message));
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error?.response?.data?.message);
      }
    }
  }
);

const initialState = {
  allInvoices: [],
  singleInvoice: {},
  errorInInvoice: ''
};

export const invoiceSlice = createSlice({
  name: 'invoices',
  initialState,
  reducers: {
    setAllInvoices: (state, action) => {
      state.allInvoices = action.payload;
    },
    setSingleInvoice: (state, action) => {
      state.singleInvoice = action.payload;
    },
    clearHasError: (state) => {
      state.errorInInvoice = '';
    }
  },
  extraReducers: {
    [addInvoiceRequest.rejected]: (state, action) => {
      state.errorInInvoice = action.payload;
    },
    [getAllInvoicesRequest.rejected]: (state, action) => {
      state.errorInInvoice = action.payload;
    },
    [getSingleInvoiceRequest.rejected]: (state, action) => {
      state.errorInInvoice = action.payload;
    },
    [updateInvoiceRequest.rejected]: (state, action) => {
      state.errorInInvoice = action.payload;
    },
    [deleteInvoiceRequest.rejected]: (state, action) => {
      state.errorInInvoice = action.payload;
    }
  }
});
export const { setAllInvoices, setSingleInvoice, clearHasError } = invoiceSlice.actions;

export default invoiceSlice.reducer;
