import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { productCategoryAPI } from '../api/productCategoryAPI';
import { appLoading, setMessage } from './appSlice';

// async thunk request to Register new Product category
export const addProductCategoryRequest = // eslint-disable-next-line
  createAsyncThunk('productCategory/addProductCategoryRequest', async (dataToSend, { dispatch, rejectWithValue }) => {
    try {
      dispatch(appLoading(true));
      const reg = await productCategoryAPI.add(dataToSend);
      dispatch(setMessage(reg.message));
      dispatch(appLoading(false));
    } catch (error) {
      if (error.response) {
        dispatch(appLoading(false));
        return rejectWithValue(error?.response?.data?.message);
      }
    }
  });

// async thunk request to get All product category
export const getAllProductCategoryRequest = createAsyncThunk(
  'productCategory/getAllProductCategoryRequest',
  // eslint-disable-next-line
  async (dt, { dispatch, rejectWithValue }) => {
    try {
      const data = await productCategoryAPI.all();
      dispatch(setAllProductCategories(data));
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error?.response?.data?.message);
      }
    }
  }
);

// async thunk request to get All Sub Categories
export const getAllSubCategoriesRequest = createAsyncThunk(
  'productCategory/getAllSubCategoriesRequest',
  // eslint-disable-next-line
  async (dt, { dispatch, rejectWithValue }) => {
    try {
      const data = await productCategoryAPI.getSubCategories(dt);
      dispatch(getRelatedSubCategories(data.subCategories));
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error?.response?.data?.message);
      }
    }
  }
);

// async thunk request to get Single request
export const getSingleProductCategoryRequest = createAsyncThunk(
  'productCategory/getSingleProductCategoryRequest',
  // eslint-disable-next-line
  async (dt, { dispatch, rejectWithValue }) => {
    try {
      dispatch(appLoading(true));
      const data = await productCategoryAPI.single(dt);
      dispatch(setSingleProductCategory(data));
      dispatch(appLoading(false));
    } catch (error) {
      if (error.response) {
        dispatch(appLoading(false));
        return rejectWithValue(error?.response?.data?.message);
      }
    }
  }
);

// async thunk request to updateProductCategoryRequest
export const updateProductCategoryRequest = createAsyncThunk(
  'productCategory/updateProductCategoryRequest',
  // eslint-disable-next-line
  async (dt, { dispatch, rejectWithValue }) => {
    try {
      dispatch(appLoading(true));
      const data = await productCategoryAPI.update(dt);
      dispatch(setMessage(data.message));
      dispatch(appLoading(false));
    } catch (error) {
      if (error.response) {
        dispatch(appLoading(false));
        return rejectWithValue(error?.response?.data?.message);
      }
    }
  }
);

// async thunk request to add subcategories
export const addSubCategoriesRequest = createAsyncThunk(
  'productCategory/addSubCategoriesRequest',
  // eslint-disable-next-line
  async (dt, { dispatch, rejectWithValue }) => {
    try {
      dispatch(appLoading(true));
      const data = await productCategoryAPI.addSubCategories(dt);
      dispatch(setMessage(data.message));
      dispatch(appLoading(false));
    } catch (error) {
      if (error.response) {
        dispatch(appLoading(false));
        return rejectWithValue(error?.response?.data?.message);
      }
    }
  }
);

// async thunk request to deleteProductCategoryRequest
export const deleteProductCategoryRequest = createAsyncThunk(
  'productCategory/deleteProductCategoryRequest',
  // eslint-disable-next-line
  async (dt, { dispatch, rejectWithValue }) => {
    try {
      dispatch(appLoading(true));
      const data = await productCategoryAPI.delete(dt);
      dispatch(setMessage(data.message));
      dispatch(appLoading(false));
    } catch (error) {
      if (error.response) {
        dispatch(appLoading(false));
        return rejectWithValue(error?.response?.data?.message);
      }
    }
  }
);

const initialState = {
  allProductCategories: [],
  allSubCategories: [],
  singleProductCategory: {},
  errorInProductCategory: ''
};

export const productCategorySlice = createSlice({
  name: 'productCategory',
  initialState,
  reducers: {
    setAllProductCategories: (state, action) => {
      state.allProductCategories = action.payload;
    },
    setSingleProductCategory: (state, action) => {
      state.singleProductCategory = action.payload;
    },
    getRelatedSubCategories: (state, action) => {
      state.allSubCategories = action.payload;
    },
    clearHasError: (state) => {
      state.errorInProductCategory = '';
    }
  },
  extraReducers: {
    [addProductCategoryRequest.rejected]: (state, action) => {
      state.errorInProductCategory = action.payload;
    },
    [getAllProductCategoryRequest.rejected]: (state, action) => {
      state.errorInProductCategory = action.payload;
    },
    [getSingleProductCategoryRequest.rejected]: (state, action) => {
      state.errorInProductCategory = action.payload;
    },
    [updateProductCategoryRequest.rejected]: (state, action) => {
      state.errorInProductCategory = action.payload;
    },
    [deleteProductCategoryRequest.rejected]: (state, action) => {
      state.errorInProductCategory = action.payload;
    },
    [addSubCategoriesRequest.rejected]: (state, action) => {
      state.errorInProductCategory = action.payload;
    },
    [getAllSubCategoriesRequest.rejected]: (state, action) => {
      state.errorInProductCategory = action.payload;
    }
  }
});
export const { setAllProductCategories, setSingleProductCategory, clearHasError, getRelatedSubCategories } =
  productCategorySlice.actions;

export default productCategorySlice.reducer;
