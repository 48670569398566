import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { faqAPI } from '../api/faqAPI';
import { setMessage } from './appSlice';

// async thunk request to Register new Faq
export const addNewFaqRequest = // eslint-disable-next-line
  createAsyncThunk('faqs/addNewFaqRequest', async (dataToSend, { dispatch, rejectWithValue }) => {
    try {
      const reg = await faqAPI.add(dataToSend);
      dispatch(setMessage(reg.message));
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error?.response?.data?.message);
      }
    }
  });

// async thunk request to get All Faqs
export const getAllFaqsRequest = createAsyncThunk(
  'faqs/getAllFaqsRequest',
  // eslint-disable-next-line
  async (dt, { dispatch, rejectWithValue }) => {
    try {
      const data = await faqAPI.all();
      dispatch(setAllFaqs(data));
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error?.response?.data?.message);
      }
    }
  }
);

// async thunk request to get Single request
export const getSingleFaqRequest = createAsyncThunk(
  'faqs/getSingleFaqRequest',
  // eslint-disable-next-line
  async (dt, { dispatch, rejectWithValue }) => {
    try {
      const data = await faqAPI.single(dt);
      dispatch(setSingleFaq(data));
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error?.response?.data?.message);
      }
    }
  }
);

// async thunk request to updateFaqRequest
export const updateFaqRequest = createAsyncThunk(
  'faqs/updateFaqRequest',
  // eslint-disable-next-line
  async (dt, { dispatch, rejectWithValue }) => {
    try {
      const data = await faqAPI.update(dt);
      dispatch(setMessage(data.message));
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error?.response?.data?.message);
      }
    }
  }
);

// async thunk request to deleteFaqRequest
export const deleteFaqRequest = createAsyncThunk(
  'faqs/deleteFaqRequest',
  // eslint-disable-next-line
  async (dt, { dispatch, rejectWithValue }) => {
    try {
      const data = await faqAPI.delete(dt);
      dispatch(setMessage(data.message));
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error?.response?.data?.message);
      }
    }
  }
);

const initialState = {
  allFaqs: [],
  singleFaq: {},
  errorInFaq: ''
};

export const faqSlice = createSlice({
  name: 'faqs',
  initialState,
  reducers: {
    setAllFaqs: (state, action) => {
      state.allFaqs = action.payload;
    },
    setSingleFaq: (state, action) => {
      state.singleFaq = action.payload;
    },
    clearHasError: (state) => {
      state.errorInFaq = '';
    }
  },
  extraReducers: {
    [addNewFaqRequest.rejected]: (state, action) => {
      state.errorInFaq = action.payload;
    },
    [getAllFaqsRequest.rejected]: (state, action) => {
      state.errorInFaq = action.payload;
    },
    [getSingleFaqRequest.rejected]: (state, action) => {
      state.errorInFaq = action.payload;
    },
    [updateFaqRequest.rejected]: (state, action) => {
      state.errorInFaq = action.payload;
    },
    [deleteFaqRequest.rejected]: (state, action) => {
      state.errorInFaq = action.payload;
    }
  }
});
export const { setAllFaqs, setSingleFaq, clearHasError } = faqSlice.actions;

export default faqSlice.reducer;
