import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { blogAPI } from '../api/blogAPI';
import { appLoading, setMessage } from './appSlice';

// async thunk request to Register New Payment Method
export const addNewBlogRequest = // eslint-disable-next-line
  createAsyncThunk('blogs/addNewBlogRequest', async (dataToSend, { dispatch, rejectWithValue }) => {
    try {
      dispatch(appLoading(true));
      const reg = await blogAPI.addBlog(dataToSend);
      dispatch(setMessage(reg.message));
      dispatch(appLoading(false));
    } catch (error) {
      if (error.response) {
        dispatch(appLoading(false));
        return rejectWithValue(error?.response?.data?.message);
      }
    }
  });

// async thunk request to get All Blog Requests
export const getAllBlogsRequest = createAsyncThunk(
  'blogs/getAllBlogsRequest',
  // eslint-disable-next-line
  async (dt, { dispatch, rejectWithValue }) => {
    try {
      dispatch(appLoading(true));
      const data = await blogAPI.allBlogs();
      dispatch(setAllBlogs(data));
      dispatch(appLoading(false));
    } catch (error) {
      if (error.response) {
        dispatch(appLoading(false));
        return rejectWithValue(error?.response?.data?.message);
      }
    }
  }
);

// async thunk request to get Single Blog
export const getSingleBlogRequest = createAsyncThunk(
  'blogs/getSingleBlogRequest',
  // eslint-disable-next-line
  async (dt, { dispatch, rejectWithValue }) => {
    try {
      dispatch(appLoading(true));
      const data = await blogAPI.singleBlog(dt);
      dispatch(setSingleBlog(data));
      dispatch(appLoading(false));
    } catch (error) {
      if (error.response) {
        dispatch(appLoading(false));
        return rejectWithValue(error?.response?.data?.message);
      }
    }
  }
);

// async thunk request to updateBlogRequest
export const updateBlogRequest = createAsyncThunk(
  'blogs/updateBlogRequest',
  // eslint-disable-next-line
  async (dt, { dispatch, rejectWithValue }) => {
    try {
      dispatch(appLoading(true));
      const data = await blogAPI.updateBlog(dt);
      dispatch(setMessage(data.message));
      dispatch(appLoading(false));
    } catch (error) {
      if (error.response) {
        dispatch(appLoading(false));
        return rejectWithValue(error?.response?.data?.message);
      }
    }
  }
);

// async thunk request to deleteBlogRequest
export const deleteBlogRequest = createAsyncThunk(
  'paymentMethods/deleteBlogRequest',
  // eslint-disable-next-line
  async (dt, { dispatch, rejectWithValue }) => {
    try {
      dispatch(appLoading(true));
      const data = await blogAPI.deleteBlog(dt);
      dispatch(setMessage(data.message));
      dispatch(appLoading(false));
    } catch (error) {
      if (error.response) {
        dispatch(appLoading(false));
        return rejectWithValue(error?.response?.data?.message);
      }
    }
  }
);

// async thunk request to Send Urls to Google
export const sendUrlsToGoogleRequest = // eslint-disable-next-line
  createAsyncThunk('blogs/sendUrlsToGoogleRequest', async (dataToSend, { dispatch, rejectWithValue }) => {
    try {
      const reg = await blogAPI.sendURLS(dataToSend);
      dispatch(setMessage(reg.message));
    } catch (error) {
      if (error.response) {
        dispatch(appLoading(false));
        return rejectWithValue(error?.response?.data?.message);
      }
    }
  });

const initialState = {
  allBlogs: [],
  singleBlog: {},
  errorInBlog: ''
};

export const blogSlice = createSlice({
  name: 'blogs',
  initialState,
  reducers: {
    setAllBlogs: (state, action) => {
      state.allBlogs = action.payload;
    },
    setSingleBlog: (state, action) => {
      state.singleBlog = action.payload;
    },
    clearHasError: (state) => {
      state.errorInBlog = '';
    }
  },
  extraReducers: {
    [addNewBlogRequest.rejected]: (state, action) => {
      state.errorInBlog = action.payload;
    },
    [getAllBlogsRequest.rejected]: (state, action) => {
      state.errorInBlog = action.payload;
    },
    [getSingleBlogRequest.rejected]: (state, action) => {
      state.errorInBlog = action.payload;
    },
    [updateBlogRequest.rejected]: (state, action) => {
      state.errorInBlog = action.payload;
    },
    [deleteBlogRequest.rejected]: (state, action) => {
      state.errorInBlog = action.payload;
    }
  }
});
export const { setAllBlogs, setSingleBlog, clearHasError } = blogSlice.actions;

export default blogSlice.reducer;
