import axios from 'axios';

const baseURL = `${process.env.REACT_APP_API_URL}`;

export const orderAPI = {
  allOrders: async () => axios.get(`${baseURL}/all-order-from-start`).then((res) => res.data),
  weeklyOrders: async () => axios.get(`${baseURL}/all-weekly-orders`).then((res) => res.data),
  monthOrders: async () => axios.get(`${baseURL}/all-monthly-orders`).then((res) => res.data),
  todayOrders: async () => axios.get(`${baseURL}/today-orders`).then((res) => res.data),
  makeAnOrder: async (info) => axios.post(`${baseURL}/create-order-paid`, info).then((res) => res.data),
  singleOrder: async (idToSearch) => axios.get(`${baseURL}/order-detail/${idToSearch}`).then((res) => res.data),
  updateOrderStatus: async (idToSearch) => {
    const { id, ...details } = idToSearch;
    return axios.put(`${baseURL}/order-detail/${id}`, details).then((res) => res.data);
  },
  confirmRejectOrder: async (idToSearch) => {
    const { id, ...details } = idToSearch;
    return axios.put(`${baseURL}/confirm-reject-appointment/${id}`, details).then((res) => res.data);
  },
  deleteOrder: async (idToSearch) => axios.delete(`${baseURL}/order-detail/${idToSearch}`).then((res) => res.data)
};
