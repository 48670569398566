import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { productSubCategoryAPI } from '../api/productSubCategoryAPI';
import { appLoading, setMessage } from './appSlice';

// async thunk request to Register new Product subcategory
export const addProductSubCategoryRequest = // eslint-disable-next-line
  createAsyncThunk(
    'productSubCategory/addProductSubCategoryRequest',
    // eslint-disable-next-line
    async (dataToSend, { dispatch, rejectWithValue }) => {
      try {
        dispatch(appLoading(true));
        const reg = await productSubCategoryAPI.add(dataToSend);
        dispatch(setMessage(reg.message));
        dispatch(appLoading(false));
      } catch (error) {
        if (error.response) {
          dispatch(appLoading(false));
          return rejectWithValue(error?.response?.data?.message);
        }
      }
    }
  );

// async thunk request to get All product sub category
export const getAllSubCategoryRequest = createAsyncThunk(
  'productSubCategory/getAllSubCategoryRequest',
  // eslint-disable-next-line
  async (dt, { dispatch, rejectWithValue }) => {
    try {
      const data = await productSubCategoryAPI.all();
      dispatch(setAllProductSubCategories(data));
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error?.response?.data?.message);
      }
    }
  }
);

// async thunk request to get Single request
export const getSingleProductSubCategoryRequest = createAsyncThunk(
  'productSubCategory/getSingleProductSubCategoryRequest',
  // eslint-disable-next-line
  async (dt, { dispatch, rejectWithValue }) => {
    try {
      dispatch(appLoading(true));
      const data = await productSubCategoryAPI.single(dt);
      dispatch(setSingleSubCategory(data));
      dispatch(appLoading(false));
    } catch (error) {
      if (error.response) {
        dispatch(appLoading(false));
        return rejectWithValue(error?.response?.data?.message);
      }
    }
  }
);

// async thunk request to updateProductSubCategoryRequest
export const updateProductSubCategoryRequest = createAsyncThunk(
  'productSubCategory/updateProductSubCategoryRequest',
  // eslint-disable-next-line
  async (dt, { dispatch, rejectWithValue }) => {
    try {
      dispatch(appLoading(true));
      const data = await productSubCategoryAPI.update(dt);
      dispatch(setMessage(data.message));
      dispatch(appLoading(false));
    } catch (error) {
      if (error.response) {
        dispatch(appLoading(false));
        return rejectWithValue(error?.response?.data?.message);
      }
    }
  }
);

// async thunk request to deleteProductSubCategoryRequest
export const deleteProductSubCategoryRequest = createAsyncThunk(
  'productSubCategory/deleteProductSubCategoryRequest',
  // eslint-disable-next-line
  async (dt, { dispatch, rejectWithValue }) => {
    try {
      dispatch(appLoading(true));
      const data = await productSubCategoryAPI.delete(dt);
      dispatch(setMessage(data.message));
      dispatch(appLoading(false));
    } catch (error) {
      if (error.response) {
        dispatch(appLoading(false));
        return rejectWithValue(error?.response?.data?.message);
      }
    }
  }
);

const initialState = {
  allProductSubCategories: [],
  singleProductSubCategory: {},
  errorInProductSubCategory: ''
};

export const productSubCategorySlice = createSlice({
  name: 'productSubCategory',
  initialState,
  reducers: {
    setAllProductSubCategories: (state, action) => {
      state.allProductSubCategories = action.payload;
    },
    setSingleSubCategory: (state, action) => {
      state.singleProductSubCategory = action.payload;
    },
    clearHasError: (state) => {
      state.errorInProductSubCategory = '';
    }
  },
  extraReducers: {
    [addProductSubCategoryRequest.rejected]: (state, action) => {
      state.errorInProductSubCategory = action.payload;
    },
    [getAllSubCategoryRequest.rejected]: (state, action) => {
      state.errorInProductSubCategory = action.payload;
    },
    [getSingleProductSubCategoryRequest.rejected]: (state, action) => {
      state.errorInProductSubCategory = action.payload;
    },
    [updateProductSubCategoryRequest.rejected]: (state, action) => {
      state.errorInProductSubCategory = action.payload;
    },
    [deleteProductSubCategoryRequest.rejected]: (state, action) => {
      state.errorInProductSubCategory = action.payload;
    }
  }
});
export const { setAllProductSubCategories, setSingleSubCategory, clearHasError } = productSubCategorySlice.actions;

export default productSubCategorySlice.reducer;
