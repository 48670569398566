import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { orderAPI } from '../api/orderAPI';
import { appLoading, setMessage } from './appSlice';

// async thunk request to Register new Order
export const addNewOrderRequest = // eslint-disable-next-line
  createAsyncThunk('orders/addNewOrderRequest', async (dataToSend, { dispatch, rejectWithValue }) => {
    try {
      const reg = await orderAPI.makeAnOrder(dataToSend);
      dispatch(setMessage(reg.message));
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error?.response?.data?.message);
      }
    }
  });

// async thunk request to Register new Order
export const changeStatusOrderRequest = // eslint-disable-next-line
  createAsyncThunk('orders/changeStatusOrderRequest', async (dataToSend, { dispatch, rejectWithValue }) => {
    try {
      const reg = await orderAPI.confirmRejectOrder(dataToSend);
      dispatch(setMessage(reg.message));
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error?.response?.data?.message);
      }
    }
  });

// async thunk request to get All orders
export const getAllOrdersRequest = createAsyncThunk(
  'orders/getAllOrdersRequest',
  // eslint-disable-next-line
  async (dt, { dispatch, rejectWithValue }) => {
    try {
      const data = await orderAPI.allOrders();
      dispatch(setAllOrders(data.orders));
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error?.response?.data?.message);
      }
    }
  }
);

// async thunk request to get All today orders
export const getTodayOrdersRequest = createAsyncThunk(
  'orders/getTodayOrdersRequest',
  // eslint-disable-next-line
  async (dt, { dispatch, rejectWithValue }) => {
    try {
      const data = await orderAPI.todayOrders();
      dispatch(setAllTodayOrders(data.todayTransactions));
      dispatch(setTodayGross(data.todayGross));
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error?.response?.data?.message);
      }
    }
  }
);

// async thunk request to get All week orders
export const getWeekOrdersRequest = createAsyncThunk(
  'orders/getWeekOrdersRequest',
  // eslint-disable-next-line
  async (dt, { dispatch, rejectWithValue }) => {
    try {
      const data = await orderAPI.weeklyOrders();
      dispatch(setAllWeekOrders(data.weekOrders));
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error?.response?.data?.message);
      }
    }
  }
);

// async thunk request to get All month orders
export const getMonthOrdersRequest = createAsyncThunk(
  'orders/getMonthOrdersRequest',
  // eslint-disable-next-line
  async (dt, { dispatch, rejectWithValue }) => {
    try {
      const data = await orderAPI.monthOrders();
      dispatch(setAllMonthOrders(data.monthlyTransactions));
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error?.response?.data?.message);
      }
    }
  }
);

// async thunk request to get Single Order request
export const getSingleOrderRequest = createAsyncThunk(
  'orders/getSingleOrderRequest',
  // eslint-disable-next-line
  async (dt, { dispatch, rejectWithValue }) => {
    try {
      const data = await orderAPI.singleOrder(dt);
      dispatch(setSingleOrder(data));
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error?.response?.data?.message);
      }
    }
  }
);

// async thunk request to updateOrderStatusRequest
export const updateOrderStatusRequest = createAsyncThunk(
  'orders/updateOrderStatusRequest',
  // eslint-disable-next-line
  async (dt, { dispatch, rejectWithValue }) => {
    try {
      const data = await orderAPI.updateOrderStatus(dt);
      dispatch(setMessage(data.message));
    } catch (error) {
      if (error.response) {
        dispatch(appLoading(false));
        return rejectWithValue(error?.response?.data?.message);
      }
    }
  }
);

// async thunk request to deleteOrderRequest
export const deleteOrderRequest = createAsyncThunk(
  'orders/deleteOrderRequest',
  // eslint-disable-next-line
  async (dt, { dispatch, rejectWithValue }) => {
    try {
      const data = await orderAPI.deleteOrder(dt);
      dispatch(setMessage(data.message));
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error?.response?.data?.message);
      }
    }
  }
);

const initialState = {
  allOrders: [],
  todayGross: 0,
  todayTotal: 0,
  weekGross: 0,
  weekTotal: 0,
  monthGross: 0,
  monthTotal: 0,
  allGross: 0,
  allTotal: 0,
  weekOrders: [],
  todayOrders: [],
  monthOrders: [],
  singleOrder: {},
  errorInOrder: ''
};

export const orderSlice = createSlice({
  name: 'orders',
  initialState,
  reducers: {
    setAllOrders: (state, action) => {
      state.allOrders = action.payload;
    },
    setAllWeekOrders: (state, action) => {
      state.weekOrders = action.payload;
    },
    setAllTodayOrders: (state, action) => {
      state.todayOrders = action.payload;
    },
    setAllMonthOrders: (state, action) => {
      state.monthOrders = action.payload;
    },
    setSingleOrder: (state, action) => {
      state.singleOrder = action.payload;
    },
    clearHasError: (state) => {
      state.errorInOrder = '';
    },
    setTodayGross: (state, action) => {
      state.todayGross = action.payload;
    },
    setTodayTotal: (state, action) => {
      state.todayTotal = action.payload;
    },
    setWeekGross: (state, action) => {
      state.weekGross = action.payload;
    },
    setWeekTotal: (state, action) => {
      state.weekTotal = action.payload;
    },
    setMonthGross: (state, action) => {
      state.monthGross = action.payload;
    },
    setMonthTotal: (state, action) => {
      state.monthTotal = action.payload;
    },
    setAllGross: (state, action) => {
      state.allGross = action.payload;
    },
    setAllTotal: (state, action) => {
      state.allTotal = action.payload;
    }
  },
  extraReducers: {
    [addNewOrderRequest.rejected]: (state, action) => {
      state.errorInOrder = action.payload;
    },
    [getAllOrdersRequest.rejected]: (state, action) => {
      state.errorInOrder = action.payload;
    },
    [getSingleOrderRequest.rejected]: (state, action) => {
      state.errorInOrder = action.payload;
    },
    [updateOrderStatusRequest.rejected]: (state, action) => {
      state.errorInOrder = action.payload;
    },
    [deleteOrderRequest.rejected]: (state, action) => {
      state.errorInOrder = action.payload;
    }
  }
});
export const {
  setAllOrders,
  setSingleOrder,
  clearHasError,
  setAllWeekOrders,
  setAllTodayOrders,
  setAllMonthOrders,
  setTodayGross,
  setTodayTotal,
  setWeekGross,
  setWeekTotal,
  setMonthGross,
  setMonthTotal,
  setAllGross,
  setAllTotal
} = orderSlice.actions;

export default orderSlice.reducer;
